<template>
  <div>
    <div class="header">
      <div class="header-wrap">
        <div class="logo">
          <router-link to="/">
            <img src="https://gwcdn.fuhua95.com/mobile/logo.png" />
          </router-link>
        </div>
        <div class="r-menu">
          <img class="menu-btn" v-if="!showRight" src="../assets/images/index-menu.png" alt="" @click="showRight = true">
          <img class="menu-btn" v-else src="../assets/images/right-close.png" alt="" @click="showRight = false">
          <!-- <router-link to="/" class="item-menu">首页</router-link>
          <router-link to="/about" class="item-menu">集团介绍</router-link>
          <div  class="item-menu item-menu-product" @mouseover="showMenu = true" @mouseleave="showMenu = false">
            <span>产品中心</span>
            
            <div class="product-list" v-if="showMenu">
              <div class="product-list-item" @click="jumpPage('/product')">源之蜂巢</div>
              <div class="product-list-item" @click="jumpPage('/product2')">脚速旅行</div>
              <div class="product-list-item" @click="jumpPage('/product3')">GM数藏</div>
            </div>
            
          </div>
          <router-link to="/groupDynamics" class="item-menu">集团动态</router-link>
          <a class="item-menu" href="https://shop.fuhua52.com/" target="_blank">周边手办</a>
          <router-link to="/joinUs" class="item-menu">联系我们</router-link> -->
          <!-- <router-link to="/contactUs" class="item-menu">联系我们</router-link> -->

        </div>
      </div>

      <!-- 右侧弹出 -->
      
    </div>
    <van-popup
      :show="showRight"
      @click-overlay="showRight = false"
      position="right"
      class="right-menu-box"
      :style="{ width: '50%', height: '100%' }">
      <van-collapse v-model="activeName" accordion>
        <div class="menu-item" @click="jumpPage('/')">首页</div>
        <div class="menu-item" @click="jumpPage('/about')">集团概述</div>
        <van-collapse-item title="产品中心" name="2">
          <div class="menu-item-child" @click="jumpPage('/dapaidui')">宇宙大派队</div>
          <div class="menu-item-child" @click="jumpPage('/fengchao')">源之蜂巢</div>
          
          <div class="menu-item-child" @click="jumpPage('/longxiang')">龍翔手游</div>
          
        </van-collapse-item>
        <div class="menu-item" @click="jumpPage('/groupDynamics')">集团动态</div>
        <a class="menu-item" href="https://shop.fuhua52.com/" target="_blank">周边手办</a>
        <div class="menu-item" @click="jumpPage('/joinUs')">联系我们</div>
      </van-collapse>
    </van-popup>

  </div>
  
</template>
<script>
// @ is an alias to /src

import { Button } from 'vant';
import { useRouter } from "vue-router";
import { Popup,Collapse, CollapseItem } from 'vant';
import {
  getCurrentInstance,
  watch,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted
} from "vue";
export default {
  name: "header",
  components: {},
  setup() {
    const activeName = ref('1');
    const data = reactive({
      showMenu:false,
      isProduct:false,
      showRight:false
    })
    const router = useRouter();
    const jumpPage = (path) => {
      data.showRight = false
      router.push({
          path:path,
         
      });
    }

    watch(
      () => router.currentRoute.value,
      (newValue) => {
        document.body.scrollIntoView()
        if(newValue.path == '/product'){
          data.isProduct = true
        }else{
          data.isProduct = false
        }
        console.log('newValue',newValue)
      },
      { immediate: true }
    )
    return {
      activeName,
      ...toRefs(data),
      jumpPage
    }
  }
};
</script>
<style lang="scss">
.right-menu-box{
  margin-top: 5.3rem;
  width: 2.5rem;
  .menu-item{
    height: 5rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "微软雅黑";
    font-weight: 400;
    color: #333333;
    // border-bottom: 0.5px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    background: #ffffff;
  }
  .menu-item:active {
    background-color:#f2f3f5;
  }
  .van-collapse-item__title{
    height: 5rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
  }
  .van-cell__title{
    font-size: 16px;
    font-family: "微软雅黑";
    font-weight: 400;
    color: #333333;
  }
  .van-cell{
    border-bottom: 1px solid #eeeeee;
  }
  .menu-item-child:active {
    background-color:#f5f5f5;
  }
  .menu-item-child{
    height: 5rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "微软雅黑";
    font-weight: 400;
    color: #666666;
    background: #f5f5f5;
  }
  .menu-item-child:last-child{
    border-bottom: 1px solid #eeeeee;
  }
  .van-collapse-item__content{
    padding: 0;
  }
}
</style>


<style scoped lang="scss">


.header {
  height: 5.4rem;
  width:100%;
  z-index: 99999999;
  background: #FFFFFF;

  box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.01);
  position:fixed;
  left: 0;
  top: 0;
 
  .header-wrap {
    width:37.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 1.6rem;
    .logo {
      width: 7.6rem;
      height: 2.4rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .r-menu {
      display: flex;
      align-items: center;
      .menu-btn{
        width: 2.2rem;
        height: 2.2rem;
      }
      .item-menu {
        margin-left: 50px;
        font-size: 18px;
        font-family: "微软雅黑";
        font-weight: 400;
        color: #333333;
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        .product-list{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 140px;
          z-index: 99999999999999;
          height: 0px;
          .product-list-item{
            width: 140px;
            height: 60px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
            font-weight: 400;
            color: #333333;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
            text-align: center;
          }
          .product-list-item:hover{
            color: #1E67FC;
            background: #F2F2F2;

          }
        }
      }
      .item-menu:hover {
        color: #1e67fc;
        font-weight: bold;
      }
      .router-link-active {
        color: #1e67fc;
        font-weight: bold;
      }
    }
  }
}


</style>