<template>
  <div class="footer">

    <div class="footer-bottom">
      <div class="footer-bottom-wrap">
        <p>
          <span>闽网文 (2022) 0947-045号</span>
          <b>|</b>
          <span>ICP证:闽B2-20230010</span>
        </p>
        <p>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022015372号-2</a> </span>
        </p>
        <p>
          <span>福建源之宇宙网络科技有限公司版权所有©2018-2023。</span>
        </p>
        <p>
          <span>地址：福建省泉州市丰泽区东海街道滨海社区大兴街72号菲莉中心1701-1706室</span>
        </p>
        <p>
          <span>健康游戏忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</span>
        </p>
        <p>
          <span>亲爱的市民朋友，福建警方反诈劝阻电话“96110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。</span>
        </p>
        <p>
          <span>违法和不良信息举报电话:4009936669</span>
        </p>
        <p>
          <span>文化部网络游戏举报和联系电子邮箱：wlyxjb@gmail.com</span>
        </p>
        <p>
          <span>扫黄打非举报</span>
        </p>
        <div class="bottom-line">
         
          <div class="line-item">
            <a href="https://www.12377.cn/" target="_blank">
              <img src="../assets/images/youhaijubao.png" alt="">
              <span>网上有害信息举报专区</span>
            </a>
            
          </div>
         
          
        </div>
        <div class="bottom-line">
          <div class="line-item">
            <a href="https://www.beian.gov.cn/" target="_blank">
              <img src="../assets/images/wenhua.png" alt="">
              <span>福建警方反诈劝阻电话96110</span>
            </a>
          </div>
          
        </div>
        <div class="bottom-line">
          <div class="line-item">
            <a href="http://jubao.fjsen.com" target="_blank">
              <img src="../assets/images/b-jubao.png" alt="">
              <span>福建省互联网违法和不良信息举报中心</span>
            </a>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getCurrentInstance, ref, reactive, defineComponent, toRefs, onBeforeMount,onMounted } from 'vue';
import moment from "moment"; 
import homeApi from '../api/api.js'
import { useRoute,useRouter } from 'vue-router'
import Bus from '../common/bus'
export default {
  name: "footers",
  setup() {
    const router = useRouter();
    const jumpPage = (path,tabIndex) => {
      document.body.scrollIntoView()
      if(tabIndex){
        
        console.log(window.location.hash)
        if(window.location.hash.indexOf(path)>-1){
          console.log("当前页")
          Bus.$emit('changeTabIndex',{tabIndex:tabIndex-1});
        }else{
          router.push({
              path: path,
              query:{
                tabIndex:tabIndex-1
              }
          });
        }
       
      }else{
        router.push({
            path: path,
        });
      }
    }

    return {
      jumpPage
    }
  }
};
</script>
<style scoped lang="scss">
.footer {
  .footer-bottom {
    width: 100%;
    background: #292C3D;
    .footer-bottom-wrap {
      width:100%;
      padding: 0 1.6rem;
      margin: 0 auto;
      font-size:12px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #cfd6e9;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      a{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #66697B;
        text-decoration: none;
        cursor: pointer;
      }
      p{
        display: flex;
        align-content: center;
        font-size: 12px;
        color: #66697B;
        margin-bottom: 10px;
        text-align: center;
        span{
          font-size: 12px !important;
          color: #66697B;
        }
        b{
          margin: 0 10px;
        }
      }
      .bottom-line{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 0.6rem;
        .line-item{
          display: flex;
          align-items: center;
          margin-right: 1rem;

          a{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #66697B;
            text-decoration: none;
          }
          img{
            width: 1.6rem;
            height: 1.6rem;
            margin-right:0.6rem;
          }
          span{
            font-size: 12px;
            color: #66697B;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>