import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
//import 'lib-flexible/flexible.js'
import './common/flexible.js'
import 'vant/lib/index.css';


function _isMobile () {
    const flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    console.log(flag)
    if (flag) {
        return false
    } else {
        return true
    }
}
if(_isMobile()){
    //pc
    console.log("pc")
    window.location.href = 'https://www.fuhua52.com/'
}else{
    //手机 切换
    console.log("手机")
    
}

console.log(_isMobile(),'5555665566')

import Vant from 'vant'
createApp(App).use(ElementPlus, {locale: zhCn}).use(store).use(Vant).use(router).mount('#app')
