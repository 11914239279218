<template>
  <div class="home">
    <div class="top-banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide swipe-items" v-for="(item,index) in bannerList" :key="index">
            <img :src="item.imgUrl" class="banner-img" alt />
            <!-- <el-image class="banner-img" :src="item.imgUrl" /> -->
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <div class="new-area-wrap">
      <div class="new-area">
        <div class="new-top">
          <div class="new-tab">
            <div class="new-tab-i new-active">
              <span>新闻动态</span>
              <b>News Trends</b>
            </div>
          </div>
          <div class="more-btn" @click="jumpPage('/groupDynamics')">查看更多</div>
        </div>
        <div class="new-content" v-if="articleList && articleList.length>0">
          <div class="new-r">
            <div class="new-item" v-for="(item,index) in articleList" :key="index" @click="jumpArticleDetail(item)">
              <div class="thumbnail">
                <img :src="item.thumbnail" alt="">
              </div>
             
              <div class="first-r">
                <div class="first-tit">{{  item.post_title }}</div>
                <b>{{ item.published_time.substring(0,10)}}</b>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <div class="product-wrap">
      <div class="product-box">
        <div class="title-box">
          <div class="title">产品介绍</div>
          <div class="small-title">Product Introduction</div>
        </div>
        <div class="product-swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide product-items" @click="jumpPage('/dapaidui')">
              <img src="https://gwcdn.fuhua95.com/mobile/home/product1.png" alt="">
            </div>
            
            <div class="swiper-slide product-items" @click="jumpPage('/fengchao')">
              <img src="https://gwcdn.fuhua95.com/mobile/home/product3.png" alt="">
            </div>
           
           
            <div class="swiper-slide product-items" @click="jumpPage('/longxiang')">
              <img src="https://gwcdn.fuhua95.com/mobile/home/product5.png" alt="">
            </div>
            
          </div>
          <div class="product-swiper-button-prev"></div>
          <div class="product-swiper-button-next"></div>
          <!-- 如果需要分页器 -->
          <div class="product-swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="hot-game-wrap">
      <div class="hot-game">
        <div class="title-box">
          <div class="title">热门游戏</div>
          <div class="small-title">Popular Games</div>
        </div>
       
        <div class="game-list">
          <img :src="item.imgUrl" alt="" v-for="(item,index) in gameList" @click="jumpGameDown(item)" :key="index" >
        </div>
      </div>
    </div>

    <div class="gongyi-wrap">
      <div class="hot-game">
        <div class="title-box gongyi-title-box">
          <div class="title">爱心公益，践行企业社会责任</div>
          <div class="small-title">Love public welfare, practice corporate social responsibility</div>
        </div>
        <div class="gongyi-swiper-container">
          <div class="gongyi-swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide gongyi-items" v-for="(item,index) in gongyiList" :key="index">
                <img :src="item.imgUrl" alt="">
                <div class="gongyi-title">
                  <p>{{ item.title }}</p>
                  <p>{{ item.title2 }}</p>
                </div>
              </div>
             
            </div>
          </div>
          
          <div class="gongyi-swiper-button-prev"></div>
          <div class="gongyi-swiper-button-next"></div>
          <!-- 如果需要分页器 -->
          <div class="gongyi-swiper-pagination"></div>
        </div>
      </div>
    </div>

   

    <div class="partner-box">
      <div class="partner-title">
        <div class="title">合作伙伴</div>
        <div class="small-title">Cooperative Partners</div>
      </div>
      <div class="partners-list">
        <div class="partners-item" v-for="(item,index) in partnersList" :key="index">
          <img :src="item.imgUrl" alt="">
        </div>
      </div>
      <div class="child-title">
        <div class="title">旗下子公司</div>
        <div class="small-title">Subsidiary Companies</div>
      </div>
      <div class="child-list">
        <div class="child-item" v-for="(item,index) in childList" :key="index">
          <img :src="item.imgUrl" alt="">
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getCurrentInstance,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted
} from "vue";
import qs from "qs";
import homeApi from "../api/api.js";
import axios from "axios";
import { useRouter } from "vue-router";
export default {
  name: "HomeView",
  setup() {
    const data = reactive({
      bannerList: [
        {
          name: "banner1",
          imgUrl:'https://gwcdn.fuhua95.com/mobile/banner1.jpg'
          // imgUrl: require("../assets/images/banner1.jpg")
        },
      ],
      newList:[],
      gameList:[
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game1.png',
          iindex:1
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game2.png',
          iindex:2
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game3.png',
          iindex:3
        },
        
        
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game4.png',
          iindex:4
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game5.png',
          iindex:5
        },
        
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game6.png',
          iindex:6
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game7.png',
          iindex:7
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game8.png',
          iindex:8
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game9.png',
          iindex:9
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game10.png',
          iindex:10
        },
        {
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/game11.png',
          iindex:11
        },
        
        
      ],
      gongyiList:[
        {
          //imgUrl: require("../assets/images/home/gongyi10.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi10.png',
          title:'我司向丰泽区教育发展基金会捐赠300万基金',
          title2:'吴克华总裁出席会议并接受政府授牌',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi11.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi11.png',
          title:'我司与泉州市妇联联合开展“友好泉州·牵手童行”小桔灯',
          title2:'关爱困境儿童“结对子”公益活动',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi12.png"),
          imgUrl: 'https://gwcdn.fuhua95.com/pc/home/gongyi12.png',
          title:'我司参与市残联、民建泉州联合举行“关爱星宝贝”结对帮扶活动，',
          title2:'筹措资金捐赠给孤独症儿童家庭',
        },
        {
          //imgUrl: require("../assets/images/home/gongyi1.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/mobile/home/gongyi1.jpg',
          title:'与泉州市妇联联合开展“关爱星星的孩子”公益活动'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi2.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/mobile/home/gongyi2.jpg',
          title:'我司执行总裁林桂清带领我司妇联关爱孤独症儿童获得盛达',
          title2:'康复启能中心送出的“关爱星星儿童，弘扬慈善美德”表彰'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi3.jpg"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/gongyi3.jpg',
          title:'吴克华总裁向峡阳中心小学捐赠款物',
          title2:'叶盛松校长上台接受捐赠'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi4.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/mobile/home/gongyi4.jpg',
          title:'我司党支部书记吴水生为峡阳中心小学优秀学生及家庭困难的学生代表送上奖学金、助学金及学习礼包'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi5.jpg"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/gongyi5.jpg',
          title:'陈正沿常务副总裁接受屏山镇副镇长赖德良代表校方',
          title2:'献上的捐赠证书'
        },
        {
          //imgUrl: require("../assets/images/home/gongyi6.jpg"),
          imgUrl: 'https://gwcdn.fuhua95.com/mobile/home/gongyi6.jpg',
          title:'吴克华总裁向石城屏山中心小学捐赠款物',
          title2:'屏山中心小学党支部副书记邓金田上台接受捐赠'
        },
      ],
      newIndex:0,
      partnersList:[
        {
          imgUrl: require("../assets/images/home/alibaba.png"),
        },
        {
          imgUrl: require("../assets/images/home/tengxun.png"),
        },
        {
          imgUrl: require("../assets/images/home/kaiying.png"),
        },
        {
          imgUrl: require("../assets/images/home/shijihuatong.png"),
        },
        {
          imgUrl: require("../assets/images/home/tanwan.png"),
        },
        {
          imgUrl: require("../assets/images/home/shenghe.png"),
        },
        {
          imgUrl: require("../assets/images/home/wangyi.png"),
        },
        {
          imgUrl: require("../assets/images/home/shengqu.png"),
        },
        {
          imgUrl: require("../assets/images/home/yimihuwu.png"),
        },
       
        
      ],
      childList:[
        {
          imgUrl: require("../assets/images/home/aoyoulongxianghuhua.png"),
        },
        {
          //imgUrl: require("../assets/images/home/child2.png"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/child2.png',
        },
        {
         // imgUrl: require("../assets/images/home/child3.png"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/child3.png',
        },
        {
          //imgUrl: require("../assets/images/home/child1.png"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/child1.png',
        },
        
        
        {
          //imgUrl: require("../assets/images/home/child4.png"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/child4.png',
        },
        {
          //imgUrl: require("../assets/images/home/child5.png"),
          imgUrl:'https://gwcdn.fuhua95.com/mobile/home/child5.png',
        },
        
      ],
      articleList:[],

    });

    //获取文章分类
    const getTypeList = () => {
      
      homeApi.articleTypeApi().then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
          let arr = res.data
          let newArr = arr.sort((a,b)=> {
            return - (a.list_order - b.list_order)
          })
          console.log(newArr,'newArr===')
          data.newList = arr
          getNewList(arr[0].id)
        }
      });
    }
    //获取文章列表
    const getNewList = (id) => {
      //?page=2&limit=5
      let params = {
        page:1,
        limit:10
      }
      homeApi.getNewListApi(id,params).then(res => {
        console.log(res)
        console.log("666res")
        if(res.code == 1){
          
          let arr = res.data.list.data
          let newArr = []
          arr.forEach((element,index) => {
            if(index<5){
              newArr.push(element)
            }
          });
          data.articleList = newArr
        }
      })
    }
    // 跳转文章详情
    const router = useRouter();
    
    const jumpPage = (path) => {
      router.push({
          path: path,
         
      });
    }
    const jumpArticleDetail = (item) => {
      if(item.type == 1){
        window.open(item.url)
      }else{
        router.push({
            path: "/groupDynamicsDetail",
            query:{
              id:item.id
            }
        });
      }
      
    }

    onMounted(() => {
      getTypeList()
      const mySwiper = new Swiper(".swiper-container", {
        autoplay: 5000,
        // loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev"
      });
      const productSwiper = new Swiper(".product-swiper-container", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".product-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".product-swiper-button-next",
        prevButton: ".product-swiper-button-prev"
      });

      const gameSwiper = new Swiper(".game-swiper-box", {
        // autoplay: 2000,
        // loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:'coverflow',
        slidesPerView : 2,
        // 如果需要分页器
        pagination: ".game-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".game-swiper-button-next",
        prevButton: ".game-swiper-button-prev",
        // onSlideChangeEnd: function(swiper){
        //   //console.log(swiper.activeIndex)
        //   // let index = swiper.activeIndex-1
        //   // if(index == -1){
        //   //   index = 1
        //   // }
        //   // if(index == 2){
        //   //   index = 0
        //   // }
        //   // data.gameBg = data.gameList[index].bgUrl
        //   console.log(index)

          
        // }
      });

      const gongyiSwiper = new Swiper(".gongyi-swiper-box", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        
        // 如果需要分页器
        pagination: ".gongyi-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".gongyi-swiper-button-next",
        prevButton: ".gongyi-swiper-button-prev",
        
      });
      
      getBannerList();

      // let data = {
      //   pageSize: 2,
      //   pageIndex: 2
      // };
      // axios({
      //   method: "post",
      //   url: "http://eeee/index.php/index/Brand/list",
      //   data: qs.stringify(data),
      //   // data:data,
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded"
      //   }
      // })
      //   .then(function(response) {
      //     console.log(response, "222222");
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //   });
    });

    const getBannerList = () => {
      let id = 1;
      homeApi.homeBannerListApi(id).then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
        }
      });
    };

    const newTab = (item,index) => {
       data.newIndex = index
       console.log(item)
       getNewList(item.id)
    }

    const jumpGameDown = (item)=> {
      return
      if(item.downUrl != ''){
        window.open(item.downUrl)
      }
      
    }

    return {
      ...toRefs(data),
      newTab,
      jumpArticleDetail,
      jumpPage,
      jumpGameDown
    };
  }
};
</script>
<style lang="scss">
.top-banner {
  .swiper-pagination-bullets {
    bottom: 50px;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: #ffffff;
    opacity: 0.7;
    transition: width 0.5s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 3rem;
    opacity: 1;
  }
}
.product-swiper-pagination{
  position: absolute;
  z-index: 100;
  .swiper-pagination-bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 0.5rem;
  }
  .swiper-pagination-bullet-active {
    width: 3rem;
    opacity: 1;
  }
}
.hot-game-wrap{
  width: 100%;
  overflow: hidden;
  padding-bottom: 1rem;
  .swiper-pagination-bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 0.5rem;
  }
  .swiper-pagination-bullet-active {
    width: 3rem;
    opacity: 1;
  }
  .game-list{
    display: flex;
    width: 37.5rem;
    overflow-y: scroll;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    img{
      width: 29.5rem;
      height: 16.6rem;
      margin-right: 1.5rem;
      border-radius: 5px;
    }
    img:first-child{
      margin-left: 1.5rem;
    }
  }
}
.gongyi-wrap{
  .swiper-pagination-bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 0.5rem;
  }
  .swiper-pagination-bullet-active {
    width: 3rem;
    opacity: 1;
  }
}
</style>
<style scoped lang="scss">
.home {
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  .top-banner {
    width: 100%;
    height: 38rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .swiper-container {
      width: 100%;
      height: 38rem;
    }

    .banner-swipe {
      width: 100%;
    }
    .demonstration {
      color: var(--el-text-color-secondary);
    }

    .el-carousel__item h3 {
      color: #475669;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
      text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .swipe-items {
      width: 100%;
      height: 38rem;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    
  }
  .new-area-wrap{
    width: 100%;
    background: #FFFFFF;
  }
  .new-area{
    width: 100%;
    margin: 0 auto;
    .new-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.6rem;
      height: 5rem;
      border-bottom: 1px solid #EEEEEE;
      .new-tab{
        display: flex;
        align-items: center;
        .new-tab-i{
          display: flex;
          align-items: center;
          cursor: pointer;
          
          span{
            font-size: 17px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: rgba(153,153,153,0.9);
          }
          b{
            font-size: 11px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-left: 1rem;
          }
          img{
            width: 8px;
            height: 27px;
            margin: 0 17px;
          }
        }
        .new-active{
          
          
          span{
            font-size: 17px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
            // background-size: 100% 2px;
          }
        }
      }
      .more-btn{
        width: 7.5rem;
        height: 2.7rem;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #1E67FC;
        font-size: 12px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #1E67FC;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .new-content{
      display: flex;
      margin-top:1.6rem;
      
      .new-r{
        flex: 1;
        padding: 0 1.6rem;
        .new-item{
          display: flex;
          cursor: pointer;
          margin-bottom: 1.3rem;
          .thumbnail{
            width: 10rem;
            height: 7rem;
            background: url('../assets/images/home/new-bg.png') no-repeat;
            background-size: 100% 100%;
            padding: 1px 1px 2px 1px;
            margin-right: 1.3rem;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .first-r{
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            .first-tit{
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(51,51,51,0.9);
              line-height: 20px;
              overflow:hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            b{
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(153,153,153,0.9);
            }
           
          }
        }
      }
    }
  }

  .product-wrap{
    width: 100%;
    position: relative;
    background: #ffffff;
    margin-top: 1rem;
    .product-box{
      width: 100%;
      
      .title-box{
        padding: 0 1.6rem;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        height: 5rem;
        border-bottom: 1px solid #EEEEEE;
        
        .title{
          font-size: 17px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
          // background-size: 100% 2px;
        }
        .small-title{
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-left: 1rem;
        }
      }
      
      .product-swiper-container{
        width: 100%;
        overflow: hidden;
        position: relative;
        .product-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -50px;
        }
        
        .swiper-wrapper{
          .product-items-warp{
            width: 100%;
            margin: 0 auto;
            display: flex;
            
          }
          .product-items{
            display: flex;
            width: 100%;
            height: 35.5rem;
            
            img{
              width:100%;
              height:100%;
            }
          }
        }
      }
    }
  }

  .hot-game-wrap{
    width: 100%;
    background: #ffffff;
    margin-top: 1rem;
    .hot-game{
      width: 100%;
      margin: 0 auto;
      .title-box{
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0 1.6rem;
        border-bottom: 1px solid #EEEEEE;
        .title{
          font-size: 17px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
          // background-size: 100% 2px;
        }
        .small-title{
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-left: 1rem;
        }
      }
      .game-swiper-container{
        width: 100%;
        height: 17.7rem;
        position: relative;
        .game-swiper-box{
          width: 100%;
          height: 17.7rem;
          overflow: hidden;
        }
        .game-items{
          width: 31.5rem !important;
          height: 17.7rem;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
        
        .game-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        }
      }
    }
  }

  .gongyi-wrap{
    width: 100%;
    background: #ffffff;
    background-size: 100% 100%;
    margin-top: 1rem;
    .hot-game{
      width: 100%;
      margin: 0 auto;
      .title-box{
        display: flex;
        margin: 0 auto;
        height: 6.5rem;
        flex-direction: column;
        padding: 0 1.6rem;
        border-bottom: 1px solid #EEEEEE;
        .title{
          font-size: 17px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
          // background-size: 22rem 2px;
          margin-top: 13px;
        }
        .small-title{
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          
        }
      }
      .gongyi-title-box{
        display: flex;
        flex-direction: column;
      }
      .gongyi-swiper-container{
        width: 100%;
        height: 22.9rem;
        position: relative;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        .gongyi-swiper-box{
          width: 34.5rem;
          height: 22.9rem;
          overflow: hidden;
          margin: 0 auto;
        }
        .gongyi-items{
          width: 34.5rem !important;
          height: 22.9rem;
          overflow: hidden;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .gongyi-title{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 1rem 1rem;
            background: rgba(0,0,0,0.5);
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
            text-align: center;
          }
        }
        .gongyi-swiper-button-prev{
          width: 2.2rem;
          height: 2.2rem;
          background: url('../assets/images/home/game-prev.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 1.6rem;
          top: 50%;
          margin-top: -1.1rem;
          cursor: pointer;
          z-index: 999999;
        }
        .gongyi-swiper-button-next{
          width: 2.2rem;
          height: 2.2rem;
          background: url('../assets/images/home/game-next.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 1.6rem;
          top: 50%;
          margin-top: -1.1rem;
          cursor: pointer;
          z-index: 999999;
        }
        .gongyi-swiper-pagination{
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        }
      }
    }
  }

 
  .partner-box{
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 1rem;
    background: #ffffff;
    padding:0 1.6rem;
    .partner-title{
      display: flex;
      align-items: center;
      height: 5rem;

      .title{
        font-size:17px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
        // background-size: 100% 2px;
      }
      .small-title{
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left:1rem;
      }
    }
    .partners-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .partners-item{
        width: 10rem;
        height: 5.7rem;
        margin-bottom: 1.2rem;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
        img{
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          z-index: 10;
        }
        img:hover {
          transform: scale(1.04);
          z-index: 100;
        }
      }
    }
    .child-title{
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.4rem;
      margin-top: 3.4rem;
      .title{
        font-size: 17px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        // background: url('../assets/images/line-bg.png') no-repeat 0 1.8rem;
        // background-size: 100% 2px;
      }
      .small-title{
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 1rem;
      }
    }
    .child-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .child-item{
        width: 10.7rem;
        height: 7.8rem;
        margin-bottom: 0.7rem;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
        img{
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
          z-index: 10;
        }
        img:hover {
          transform: scale(1.04);
          z-index: 100;
        }
      }
    }
  }
  
}
</style>
